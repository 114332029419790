
import { defineComponent, onMounted, ref } from "vue";
import invitados from "@/services/miGrupo.service";
export default defineComponent({
  name: "invited",
  setup() {
    const usuarios = ref([]);
    const listarInvitados = () => {
      invitados.listarUsuarios().then((response) => {
        console.log(response);
        usuarios.value = response.data.data;
      });
    };
    onMounted(() => {
      listarInvitados();
    });
    return { usuarios };
  },
});
