<template>
  <div class="d-flex flex-wrap flex-stack mb-6">
    <h3 class="fw-bolder my-2 text-blue">Mis grupos</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="d-lg-flex d-md-flex d-sm-block p-3">
        <div class="col-lg-8 col-md-6 col-sm-12 col-sm-12">
          <div class="col-lg-6 col-sm-12 d-flex align-items-center">
            <i class="bi bi-search"></i>
            <input
              type="text"
              class="form-control p-2 mx-2"
              placeholder="Buscar"
              name=""
              id=""
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-sm-12">
          <div class="d-flex justify-content-end mt-2">
            <router-link
              class="btn bg-white text-center mx-2 shadow-sm fw-normal border"
              style="border-radius: 16px"
              to="mi-grupo-agregar-invitado"
            >
              <i class="bi bi-person-fill text-blue"></i>
              Agregar invitado
            </router-link>
            <router-link
              class="btn bg-white text-center shadow-sm fw-normal border"
              style="border-radius: 16px"
              to="mi-grupo-agregar-grupo"
            >
              <i class="bi bi-people-fill text-blue"></i>
              Agregar nuevo grupo
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row g-6 bg-trasnparent" style="height: calc(100% - 47px)">
    <div class="col-md-8 col-sm-12 overflow-auto" style="height: 67vh">
      <div v-for="(grupo, i) in grupos" :key="i" id="grupos">
        <div class="card p-0 mb-5 shadow-sm">
          <div class="card-body py-2 px-5">
            <div class="row">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <router-link
                    class="btn p-2"
                    :to="{
                      name: 'mi-grupo-editar-grupo',
                      params: { id: grupo._id },
                    }"
                  >
                    <i class="bi bi-pencil-fill text-blue"></i>
                  </router-link>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#modalEliminarGrupo"
                    class="btn p-2"
                    @click="grupoSelectBorrar(grupo._id)"
                  >
                    <i class="bi bi-trash-fill text-blue"></i>
                  </button>
                  <div
                    class="modal fade"
                    id="modalEliminarGrupo"
                    tabindex="-1"
                    aria-labelledby="modalEliminarGrupoLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      style="
                        border-radius: 38px;
                        max-height: 321px;
                        max-width: 375px;
                      "
                    >
                      <div class="modal-content p-5">
                        <div class="text-center my-5">
                          <h2 class="text-blue">¿Eliminar?</h2>
                        </div>
                        <div class="text-gray text-center my-5">
                          <p>
                            ¿¡Está seguro de querer eliminar este invitado!?
                          </p>
                        </div>
                        <div class="text-center text-gray my-5">
                          <p>
                            Debe tener en cuenta que al eliminar el invitado el
                            mismo será eliminado de todos los grupos en los que
                            se encuentre .
                          </p>
                        </div>
                        <div class="text-center my-5">
                          <button
                            @click="borrarGrupo"
                            class="btn bg-blue rounded-pill text-white"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                          >
                            Confirmar
                          </button>
                        </div>
                        <div class="text-center">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            class="btn text-blue rounded-pill"
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex border-end h-100">
                  <div>
                    <div class="p-0 me-2">
                      <img
                        src="media/avatars/blank.png"
                        alt=""
                        class="h-50 rounded-circle"
                        style="width: 50px"
                      />
                    </div>
                  </div>
                  <div class="d-block mx-3">
                    <h4 class="text-blue">{{ grupo.nombre }}</h4>
                    <p>
                      <span class="fw-bold">Nº de integrantes:</span>
                      {{ grupo.invitadosCantidad }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex border-end">
                  <div class="text-end">
                    <span class="fw-bold">Ubicaciones:</span>
                  </div>
                  <div>
                    <ul class="p-0 mx-1" style="list-style: none">
                      <li v-for="(sede, l) in grupo.sedes" :key="l">
                        {{ sede.nombre }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex">
                  <div class="text-end">
                    <span class="fw-bold">Total equipos: </span>
                  </div>
                  <div class="mx-1">
                    <p>{{ grupo.equiposCantidad }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="grupo.nombre">
                  <button
                    class="bg-gray-input border-0 w-100 justify-content-end text-end p-2"
                    type="button"
                    @click="listarUsuarios(grupo._id, i)"
                  >
                    <span class="text-blue" style="font-size: 14px">
                      Ver integrantes
                    </span>
                    <i
                      class="bi bi-chevron-double-down fw-bolder text-blue"
                    ></i>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="grupo.nombre"
                  data-bs-parent="acordeon"
                  v-if="grupo.isVisible"
                >
                  <div
                    class="accordion-body overflow-scroll"
                    style="max-height: 278px"
                  >
                    <div
                      class="d-flex justify-content-between my-5"
                      v-for="(user, u) in usuariosPorGrupo"
                      :key="u"
                      :value="user._id"
                      id="usuariosPorGrupo"
                    >
                      <div class="d-flex">
                        <div class="p-0 me-2 d-flex align-items-center">
                          <img
                            src="media/avatars/blank.png"
                            alt=""
                            class="rounded-circle"
                            style="width: 40px; height: 40px"
                          />
                          <p class="mx-4">{{ user.nombre }}</p>
                        </div>
                      </div>
                      <div class="d-flex">
                        <router-link
                          :to="{
                            name: 'editar-invitados',
                            params: { id: user._id },
                          }"
                          class="btn p-2"
                        >
                          <i class="bi bi-pencil-fill text-blue"></i>
                        </router-link>
                        <button
                          class="btn p-2"
                          data-bs-toggle="modal"
                          data-bs-target="#modalEliminarUsuario"
                          @click="userSelectDelete(user._id)"
                        >
                          <i class="bi bi-trash-fill text-blue"></i>
                        </button>
                        <div
                          class="modal fade"
                          id="modalEliminarUsuario"
                          tabindex="-1"
                          aria-labelledby="modalEliminarUsuarioLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered"
                            style="
                              border-radius: 38px;
                              max-height: 321px;
                              max-width: 375px;
                            "
                          >
                            <div class="modal-content p-5">
                              <div class="text-center my-5">
                                <h2 class="text-blue">¿Eliminar?</h2>
                              </div>
                              <div class="text-gray text-center my-5">
                                <p>
                                  ¿¡Está seguro de querer eliminar este
                                  invitado!?
                                </p>
                              </div>
                              <div class="text-center text-gray my-5">
                                <p>
                                  Debe tener en cuenta que al eliminar el
                                  invitado el mismo será eliminado de todos los
                                  grupos en los que se encuentre .
                                </p>
                              </div>
                              <div class="text-center my-5">
                                <button
                                  @click="borrarUsuario(user._id)"
                                  class="btn bg-blue rounded-pill text-white"
                                  aria-label="Close"
                                  data-bs-dismiss="modal"
                                >
                                  Confirmar
                                </button>
                              </div>
                              <div class="text-center">
                                <button
                                  type="button"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  class="btn text-blue rounded-pill"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <invited class="h-50"></invited>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import invited from "@/components/widgets/invitados/Invited.vue";
import miGrupoService from "@/services/miGrupo.service";
import Swal from "sweetalert2";

export default defineComponent({
  name: "documents",
  components: { invited },
  setup() {
    const grupos = ref([]);
    const usuariosPorGrupo = ref([]);
    const userSelect = ref("");
    const traerGrupos = () => {
      miGrupoService.listarGrupos().then((data) => {
        data.data.data.map((a) => {
          a.isVisible = false;
        });
        grupos.value = data.data.data;
        console.log("mis grupos", data.data);
      });
    };
    const listarUsuarios = (id, indice) => {
      grupos.value[indice].isVisible = !grupos.value[indice].isVisible;
      if (!grupos.value[indice].isVisible) return;
      miGrupoService.listarUsuariosPorGrupo(id).then((result) => {
        usuariosPorGrupo.value = result.data;
        console.log(result);
      });
    };
    const userSelectDelete = (value) => {
      userSelect.value = value;
    };
    const borrarUsuario = () => {
      console.log(userSelect.value);
      miGrupoService.eliminarUsuarios(userSelect.value).then((res) => {
        console.log(res);
        Swal.fire(
          "Borrado",
          "Este usuario se ah borrado con exito",
          "success"
        ).then((res) => {
          traerGrupos();
        });
      });
    };
    const grupoIdDelete = ref("");
    const grupoSelectBorrar = (value) => {
      grupoIdDelete.value = value;
      console.log(value);
    };
    const borrarGrupo = () => {
      miGrupoService.eliminarGrupo(grupoIdDelete.value).then((res) => {
        console.log(res);
        Swal.fire(
          "Borrado",
          "Este grupo se ah borrado con exito",
          "success"
        ).then((res) => {
          traerGrupos();
        });
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Mis grupos", ["Pages", "Profile"]);
      traerGrupos();
    });

    return {
      grupos,
      listarUsuarios,
      usuariosPorGrupo,
      borrarUsuario,
      userSelectDelete,
      borrarGrupo,
      grupoSelectBorrar,
    };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
</style>
